import * as React from 'react';
import { NextPage } from 'next';

import getAuthorsDetail from 'utils/wp/getAuthorsDetail';
import { mafindo, wp } from 'utils/api';
import { WordPressPostIndex, WordPressUser } from 'types/wp';
import { DekontaminasiHoaxPosts } from 'types/dekontaminasi';
import { PageWrapper, Content, Column } from 'components/layout';
import { Stack } from 'components/design-system';
import {
  Hero,
  ImportantLinksSection,
  SocialMediaSection,
  CasesSection,
  VaccinateSection,
  CategorySection,
} from 'modules/home';

interface IndexPageProps {
  information?: WordPressPostIndex[];
  infographic?: WordPressPostIndex[];
  verification?: WordPressPostIndex[];
  sticky?: WordPressPostIndex[];
  authors?: Record<number, WordPressUser>;
  errors?: string;
}

const Section = Content.withComponent('section');

const IndexPage: NextPage<IndexPageProps> = ({
  information,
  infographic,
  verification,
  sticky,
  authors,
}) => (
  <PageWrapper pageTitle="Beranda">
    <Hero />
    <Section>
      <Column>
        <Stack spacing="xxl">
          <CasesSection />
          <VaccinateSection />
          <CategorySection
            posts={sticky}
            authors={authors}
            slug="bacaan"
            title="Bacaan Pilihan"
            learnMore="Lihat Semua Bacaan"
            hasExcerpt
          />
          <CategorySection
            posts={information}
            authors={authors}
            slug="artikel"
            title="Informasi Terkini"
            learnMore="Lihat Semua Berita"
            hasExcerpt
          />
          <CategorySection
            posts={infographic}
            authors={authors}
            slug="infografik"
            title="Infografik Terbaru"
            learnMore="Lihat Semua Infografik"
          />
          <CategorySection
            posts={verification}
            authors={authors}
            slug="verifikasi"
            title="Periksa Fakta"
            learnMore="Lihat Semua Fakta"
          />
          <ImportantLinksSection />
          <SocialMediaSection />
        </Stack>
      </Column>
    </Section>
  </PageWrapper>
);

export async function getStaticProps() {
  try {
    const [informationCategory, infographicCategory, verificationCategory] = await Promise.all([
      wp<Array<{ id: string }>>('wp/v2/categories', {
        slug: 'artikel',
        _fields: 'id,count,description,name,slug',
      }),
      wp<Array<{ id: string }>>('wp/v2/categories', {
        slug: 'infografik',
        _fields: 'id,count,description,name,slug',
      }),
      wp('wp/v2/categories', {
        slug: 'verifikasi',
        _fields: 'id,count,description,name,slug',
      }),
    ]);

    if (informationCategory && infographicCategory && verificationCategory) {
      let sticky: WordPressPostIndex[] | undefined;
      let information: WordPressPostIndex[] | undefined;
      let infographic: WordPressPostIndex[] | undefined;
      let verification: DekontaminasiHoaxPosts[] | undefined;
      let authors: Record<string, WordPressUser> | undefined;

      const [
        unfilteredInformationPosts,
        unfilteredInfographicPosts,
        unfilteredStickyPosts,
        verificationPosts,
      ] = await Promise.all([
        wp<WordPressPostIndex[]>('wp/v2/posts', {
          categories: informationCategory[0].id,
          _fields: 'id,date_gmt,modified_gmt,type,slug,title,excerpt,author',
        }),
        wp<WordPressPostIndex[]>('wp/v2/posts', {
          categories: infographicCategory[0].id,
          _fields: 'id,date_gmt,modified_gmt,type,slug,title,excerpt,author',
        }),
        wp<WordPressPostIndex[]>('wp/v2/posts', {
          sticky: true,
          _fields: 'id,date_gmt,modified_gmt,type,slug,title,excerpt,author',
        }),
        mafindo<DekontaminasiHoaxPosts[]>(),
      ]);

      if (Array.isArray(unfilteredInformationPosts)) {
        const posts = unfilteredInformationPosts.filter(post => post.type === 'post');
        information = posts.slice(0, 4);
      }

      if (Array.isArray(unfilteredInfographicPosts)) {
        const posts = unfilteredInfographicPosts.filter(post => post.type === 'post');
        infographic = posts.slice(0, 4);
      }

      if (Array.isArray(verificationPosts)) {
        verification = verificationPosts.slice(0, 2);
      }

      if (Array.isArray(unfilteredStickyPosts)) {
        const posts = unfilteredStickyPosts.filter(post => post.type === 'post');
        sticky = posts.slice(0, 2);
      }

      if (information?.length || infographic?.length || verification?.length) {
        const authorsIdList = [
          ...(information || []),
          ...(infographic || []),
          ...(sticky || []),
        ].map(post => post.author);

        const uniqueAuthors = [...new Set(authorsIdList)];
        const authorsDetail = await getAuthorsDetail(uniqueAuthors);
        const map = authorsDetail.reduce<Record<string, WordPressUser>>((obj, item) => {
          // eslint-disable-next-line no-param-reassign
          obj[item.id] = item;
          return obj;
        }, {});

        authors = map;
      }

      return { props: { information, infographic, verification, sticky, authors } };
    }

    throw new Error('Failed to fetch posts');
  } catch (err) {
    return { props: { errors: err.message } };
  }
}

export default IndexPage;
